import useSearchSettings from "@municipio/gatsby-theme-basic/src/hooks/searchSettings";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import SearchForm from "../SearchForm/SearchForm.js";

export default function HeaderFrontPageSearch({ className, ...restProps }) {
  const { contentNode: { isFrontPage } = {} } = usePageContext();
  const {
    acfOptionsThemeOptions: {
      themeFeatures: { enabledFeatures },
    },
  } = useStaticQuery(graphql`
    query HeaderSearchSettings {
      wp {
        acfOptionsThemeOptions {
          themeFeatures {
            enabledFeatures
          }
        }
      }
    }
  `).wp;
  const searchSettings = useSearchSettings();
  if (!isFrontPage) {
    return null;
  }
  if (!enabledFeatures?.includes("frontpage-hero-search")) {
    return null;
  }
  return (
    <div
      className={cx(
        "o-container",
        "c-header__search",
        "u-hidden-print",
        className,
      )}
      {...restProps}
    >
      <SearchForm
        className="article-wrapper c-search-form c-search-form--main"
        placeholderText={`Vad letar du efter?`}
        settings={searchSettings.searchDisplay}
      />
    </div>
  );
}
