import { Link } from "@whitespace/components";
import cx from "classnames";
import React from "react";

import Icon from "../../Icon/Icon";

export default function EventSectionLinks({
  apple_music,
  deezer,
  facebook,
  google_music,
  instagram,
  soundcloud,
  spotify,
  twitter,
  vimeo,
  youtube,
  className,
  ...restProps
}) {
  return (
    <div className={cx("event__details", className)} {...restProps}>
      <ul className="event__list">
        {facebook && (
          <li className="event__list-item">
            <Link to={facebook} className={"event__list-link"}>
              <Icon
                name="social-media-facebook-1"
                size="16"
                className="event__title-icon"
              />
              Evenemanget på Facebook
            </Link>
          </li>
        )}
        {twitter && (
          <li className="event__list-item">
            <Link to={twitter} className={"event__list-link"}>
              <Icon
                name="social-media-twitter"
                size="16"
                className="event__title-icon"
              />
              Evenemanget på Twitter
            </Link>
          </li>
        )}
        {instagram && (
          <li className="event__list-item">
            <Link to={instagram} className={"event__list-link"}>
              <Icon
                name="social-instagram"
                size="16"
                className="event__title-icon"
              />
              Evenemanget på Instagram
            </Link>
          </li>
        )}
        {google_music && (
          <li className="event__list-item">
            <Link to={google_music} className={"event__list-link"}>
              <Icon
                name="google-play-custom"
                size="16"
                className="event__title-icon"
              />
              Evenemanget på Google Music
            </Link>
          </li>
        )}
        {apple_music && (
          <li className="event__list-item">
            <Link to={apple_music} className={"event__list-link"}>
              <Icon
                name="os-system-apple"
                size="16"
                className="event__title-icon"
              />
              Evenemanget på Apple Music
            </Link>
          </li>
        )}
        {spotify && (
          <li className="event__list-item">
            <Link to={spotify} className={"event__list-link"}>
              <Icon
                name="os-system-apple"
                size="16"
                className="event__title-icon"
              />
              Evenemanget på spotify
            </Link>
          </li>
        )}
        {soundcloud && (
          <li className="event__list-item">
            <Link to={soundcloud} className={"event__list-link"}>
              <Icon
                name="social-music-soundcloud"
                size="16"
                className="event__title-icon"
              />
              Evenemanget på Soundcloud
            </Link>
          </li>
        )}
        {deezer && (
          <li className="event__list-item">
            <Link to={deezer} className={"event__list-link"}>
              <Icon
                name="social-media-deezer-custom"
                size="16"
                className="event__title-icon"
              />
              Evenemanget på Deezer
            </Link>
          </li>
        )}
        {youtube &&
          (youtube.length > 1 ? (
            <li className="event__list-item">
              <Icon
                name="social-video-youtube-clip"
                size="16"
                className="event__title-icon"
              />
              Evenemanget på Youtube
              <ul>
                {youtube.map(({ youtube_link }, index) => {
                  return (
                    <li key={index}>
                      <Link to={youtube_link} className={"event__list-link"}>
                        {youtube_link}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          ) : (
            !!youtube?.[0]?.youtube_link && (
              <li className="event__list-item">
                <Link
                  to={youtube[0].youtube_link}
                  className={"event__list-link"}
                >
                  <Icon
                    name="social-video-youtube-clip"
                    size="16"
                    className="event__title-icon"
                  />
                  Evenemanget på Youtube
                </Link>
              </li>
            )
          ))}
        {vimeo &&
          (vimeo.length > 1 ? (
            <li className="event__list-item">
              <Icon
                name="social-video-vimeo"
                size="16"
                className="event__title-icon"
              />{" "}
              Evenemanget på Vimeo
              <ul>
                {vimeo.map((link, index) => {
                  return (
                    <li key={index}>
                      <Link to={link} className={"event__list-link"}>
                        {link}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          ) : (
            <li className="event__list-item">
              <Link to={vimeo[0]} className={"event__list-link"}>
                <Icon
                  name="social-video-vimeo"
                  size="16"
                  className="event__title-icon"
                />
                Evenemanget på Vimeo
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}
