import { useStaticQuery, graphql } from "gatsby";

export default function useThemeSettings() {
  return useStaticQuery(graphql`
    query ThemeSettings {
      wp {
        acfOptionsThemeOptions {
          themeLogo {
            themeLogo {
              sourceUrl
            }
            themeFooterLogo {
              sourceUrl
            }
          }
        }
      }
    }
  `).wp.acfOptionsThemeOptions.themeLogo;
}
