import React, { createContext } from "react";

import { AlertProvider } from "./hooks/alert";
import { MatomoScript } from "./hooks/matomo";

export const pageWrapperContext = createContext();
export const PageWrapperContextProvider = pageWrapperContext.Provider;

export default function PageWrapper({ children }) {
  return (
    <>
      {!process.env.GATSBY_DISABLE_ALERT ? (
        <AlertProvider>{children}</AlertProvider>
      ) : (
        children
      )}
      {process.env.GATSBY_MATOMO_URL && <MatomoScript />}
    </>
  );
}
