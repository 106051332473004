import { H, Section } from "@jfrk/react-heading-levels";
import { PageGrid, PageGridItem } from "@whitespace/components";
import {
  Time,
  BoxNavigation,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import cx from "classnames";
import React from "react";

import usePageHeader from "../../hooks/page-header";
import { FluidImage } from "../Image";

import ArticleFooter from "./ArticleFooter";

export default function Article({
  title,
  featuredImage,
  publishedDate,
  modifiedDate,
  managedBy,
  childPages,
  preamble,
  taxonomyTerms,
  children,
  className,
  ...restProps
}) {
  const { processContent } = useHTMLProcessor();

  const { boxNavigationPlacing } = usePageHeader();

  return (
    <PageGrid
      as="article"
      className={cx("c-article", className)}
      {...restProps}
    >
      {featuredImage && featuredImage.src && (
        <PageGridItem colspan="12">
          <FluidImage
            base64={featuredImage.base64}
            src={featuredImage.src}
            srcSet={featuredImage.srcSet}
            srcWebp={featuredImage.srcWebp}
            srcSetWebp={featuredImage.srcSetWebp}
            aspectRatio={1216 / 532}
            width={featuredImage.width}
            height={featuredImage.height}
            alt={featuredImage.altText}
            caption={processContent(featuredImage.caption)}
            credit={featuredImage.photograph.name}
            className="o-grid-block o-grid-block--100 c-article__featured-image"
          />
        </PageGridItem>
      )}
      <PageGridItem
        className={cx(
          "c-article__header",
          boxNavigationPlacing === "above" && "c-article__header--flip",
        )}
      >
        {title ? <H className="c-article__title">{title}</H> : null}
        {publishedDate ? (
          <p className="c-article__pub-date">
            Publicerad:{" "}
            <Time
              date={publishedDate}
              format={{
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              }}
            />
          </p>
        ) : null}

        {childPages && childPages.length ? (
          <div>
            <div className="">
              <div className="c-hero-butler__content o-margin-small">
                <BoxNavigation items={childPages} />
              </div>
            </div>
          </div>
        ) : null}
      </PageGridItem>

      {preamble && preamble.text && (
        <PageGridItem>
          <div
            className={cx("preamble", preamble.image && "preamble--w-image")}
          >
            <div className="preamble__text">{preamble.text}</div>
            {preamble.image && preamble.image.src && (
              <div className="preamble__image">
                {preamble.image.mimeType.includes("svg") ? (
                  // <div
                  //   className="preamble__image-svg"
                  //   dangerouslySetInnerHTML={{
                  //     __html: preamble.image.fileContent,
                  //   }}
                  // />
                  <img
                    className="preamble__image-svg"
                    src={preamble.image.src}
                  />
                ) : (
                  <FluidImage
                    base64={preamble.image.base64}
                    src={preamble.image.src}
                    srcSet={preamble.image.srcSet}
                    srcWebp={preamble.image.srcWebp}
                    srcSetWebp={preamble.image.srcSetWebp}
                    width={preamble.image.width}
                    height={preamble.image.height}
                    alt={preamble.image.altText}
                    caption={processContent(preamble.image.caption)}
                    credit={preamble.image.photograph.name}
                  />
                )}
              </div>
            )}
          </div>
        </PageGridItem>
      )}
      <Section>
        {children}
        <PageGridItem>
          {(modifiedDate || managedBy || taxonomyTerms) && (
            <ArticleFooter
              modifiedDate={modifiedDate}
              managedBy={managedBy}
              taxonomyTerms={taxonomyTerms}
            />
          )}
        </PageGridItem>
      </Section>
    </PageGrid>
  );
}
