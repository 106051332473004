import React from "react";

import BulletinPageTemplate from "../../../templates/BulletinPageTemplate";
import EventPageTemplate from "../../../templates/EventPageTemplate";
import InfoPageTemplate from "../../../templates/InfoPageTemplate";
import LandingPageTemplate from "../../../templates/LandingPageTemplate";
import OperationalStatusPageTemplate from "../../../templates/OperationalStatusPageTemplate";
import PostPageTemplate from "../../../templates/PostPageTemplate";
import StartPageTemplate from "../../../templates/StartPageTemplate";

export default function SingleTemplate({ pageContext }) {
  const {
    contentNode: { contentType, pageAppearance, isFrontPage },
    // isPreview,
  } = pageContext;
  switch (contentType?.node?.name) {
    case "bulletin":
      return <BulletinPageTemplate pageContext={pageContext} />;
    case "event":
      return <EventPageTemplate pageContext={pageContext} />;
    case "operational-status":
      return <OperationalStatusPageTemplate pageContext={pageContext} />;
    case "post":
      return <PostPageTemplate pageContext={pageContext} />;
    case "page":
      if (isFrontPage) {
        return <StartPageTemplate pageContext={pageContext} />;
      }
      switch (pageAppearance?.template) {
        case "default":
          return <InfoPageTemplate pageContext={pageContext} />;
        case "landingPage":
          return <LandingPageTemplate pageContext={pageContext} />;
        default:
          console.error(
            `No template found for WP template "${pageAppearance?.template}"`,
          );
          return null;
      }
    default:
      console.error(
        `No template found for WP content type "${contentType?.node?.name}"`,
      );
      return null;
  }
}
