import { Section, H } from "@jfrk/react-heading-levels";
import { Link } from "@whitespace/components";
import React from "react";

export default function EventSectionPlace({ location, ...restProps }) {
  return (
    <Section>
      <div className="event__place">
        <Link
          to={`https://www.google.com/maps/search/?api=1&query=${location.street_address}+${location.postal_code}+${location.city}&zoom=18`}
          className={"event__place-link"}
        >
          {location.title}
        </Link>
        <address className="event__place-information">
          {location.street_address && <p>{location.street_address}</p>}
          {location.postal_code && <p>{location.postal_code}</p>}
          {location.city && <p>{location.city}</p>}
        </address>
      </div>
    </Section>
  );
}
