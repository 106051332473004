import useThemeSettings from "@municipio/gatsby-theme-basic/src/hooks/theme";
import { Link, withComponentDefaults } from "@whitespace/components";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./HeaderLogo.module.css";

HeaderLogo.propTypes = {
  "aria-label": PropTypes.string,
  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
  linkProps: PropTypes.object,
  linkTo: PropTypes.any,
  styles: PropTypes.objectOf(PropTypes.string),
};

function DefaultLogo({ ...restProps }) {
  const { themeLogo } = useThemeSettings();

  const query = useStaticQuery(graphql`
    query SiteLogoQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  if (themeLogo && themeLogo.sourceUrl) {
    return <img src={themeLogo.sourceUrl} alt="" />;
  }
  const siteTitle = query?.site?.siteMetadata?.title;
  if (siteTitle) {
    return <span {...restProps}>{siteTitle}</span>;
  }
  return null;
}

export default withComponentDefaults(HeaderLogo, "headerLogo");

function HeaderLogo({
  "aria-label": ariaLabel,
  className,
  components: { Logo = DefaultLogo } = {
    Logo: DefaultLogo,
  },
  linkProps: { ...linkRestProps } = {},
  linkTo,
  styles = defaultStyles,
  ...restProps
}) {
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <Link
        to={linkTo}
        className={clsx(styles.link)}
        aria-label={ariaLabel || "Staffanstorps logotyp"}
        {...linkRestProps}
      >
        <Logo className={styles.text} />
      </Link>
    </div>
  );
}
