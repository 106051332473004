import { Link } from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks/src";
import cx from "classnames";
import Img from "gatsby-image";
import React, { useRef } from "react";

export default function FluidImage({
  WrapperComponent = null,
  src,
  srcSet,
  srcWebp,
  srcSetWebp,
  width,
  height,
  base64,
  aspectRatio,
  alt,
  caption,
  credit,
  linkTo,
  estimatedWidth = 320,
  imgProps: { className: imgClassName, ...imgRestProps } = {},
  linkProps: { className: linkClassName, ...linkRestProps } = {},
  className,
  ...restProps
}) {
  let ref = useRef(null);

  let { width: componentWidth } = useComponentSize(ref, {
    width: estimatedWidth,
    height: (estimatedWidth / 16) * 9,
  });

  if (WrapperComponent == null) {
    WrapperComponent = caption ? "figure" : "div";
  }

  return (
    <WrapperComponent
      className={cx("c-image", className)}
      ref={ref}
      {...restProps}
    >
      <Link
        to={linkTo}
        className={cx("c-image__link", linkClassName)}
        {...linkRestProps}
      >
        <Img
          fluid={{
            src,
            srcSet,
            srcWebp,
            srcSetWebp,
            sizes: `${componentWidth}px`,
            aspectRatio: aspectRatio || width / height,
            width,
            height,
            base64,
            alt,
          }}
          alt={alt}
          className={cx("c-image__image", imgClassName)}
          {...imgRestProps}
        />
      </Link>
      {caption || credit ? (
        <figcaption className="c-image__caption">
          {caption && <>{caption}</>}
          {credit && (
            <p className="c-image__photographer">{"Fotograf: " + credit}</p>
          )}
        </figcaption>
      ) : null}
    </WrapperComponent>
  );
}
