export default {
  color: {
    primary: "#C5DEC6",
    primaryDarken: "#BBD9BC",
    primaryLightest: "#C5DEC610",
    text: "#1E1D1D",
    textInverse: "#fff",
    bg: "#fff",
    link: "#3B657C",
    linkInverse: "#fff",
  },
  searchFormFieldHeaderBorder: "var(--color-link)",
  searchFormHeaderIconColor: "var(--color-link)",
  searchFormSubmitHoverBackground: "var(--header-background)",
  searchFormSubmitHoverIconColor: "var(--color-link)",
  linkColor: "var(--color-link)",
  colorAction: "var(--color-link)",
  colorActionActive: "var(--color-link)",
  headerMainMenuColor: "#1E1D1D",
  headerMainTextDecoration: "none",
  headerMainHoverTextDecoration: "underline",
  header: {
    background: "#F0F3F0",
    color: "var(--color-link)",
  },
  headerFlyOut: {
    toggle: {
      borderColor: "var(--color-primary)",
      color: "#234B67",
      backgroundColor: "var(--color-primary)",
      hover: {
        borderColor: "var(--color-primary-darken)",
        color: "#234B67",
        backgroundColor: "var(--color-primary-darken)",
      },
    },
  },
  headerMainMenu: {
    background: "var(--color-primary)",
    color: "#234B67",
  },
  footer: {
    background: "var(--color-primary)",
    color: "#234B67",
    linkColor: "#234B67",
    dividerBorderTopColor: "var(--color-primary-lightest)",
  },
  gridMenu: {
    backgroundColor: "var(--color-primary)",
  },
  card: {
    background: "var(--color-primary)",
    color: "#234B67",
    titleColor: "#234B67",
    metaColor: "#234B67",
    hover: {
      background: "var(--color-primary-darken)",
      color: "#234B67",
    },
  },
  boxNavigation: {
    linkBackground: "var(--color-primary)",
    linkColor: "#234B67",
    linkHoverColor: "var(--color-link)",
    linkHoverBackground: "var(--color-primary-darken)",
    linkCurrentColor: "#234B67",
  },
  coverLinkColor: "#234B67",
  teaserMetaColor: "#4e4949",
  pageThemeTextColor: "#234B67",
};
