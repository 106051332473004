import * as defaultStyles from "@municipio/gatsby-theme-basic/src/components/modularity-modules/VideoModule.module.css";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper.js";
import VideoIframe from "@municipio/gatsby-theme-basic/src/components/VideoIframe.js";
import clsx from "clsx";
import urlParser from "js-video-url-parser";
import PropTypes from "prop-types";
import React from "react";

VideoModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.shape({
    modVideoOptions: PropTypes.shape({
      // type: PropTypes.string,
      // videoMp4: PropTypes.shape({
      //   mediaItemUrl: PropTypes.string,
      //   mimeType: PropTypes.string,
      // }),
      embedLink: PropTypes.string,
    }),
  }),
};

export default function VideoModule({
  className,
  module = {},
  styles = defaultStyles,
  title,
  ...restProps
}) {
  const { modVideoOptions: { embedLink } = {} } = module;
  const url =
    typeof embedLink === "string" && /\bplay\.mediaflowpro\b/.test(embedLink)
      ? embedLink.match(/"([^"]+\bplay\.mediaflowpro\b[^"]+)"/)?.[1]
      : urlParser.create({
          videoInfo: urlParser.parse(embedLink),
          format: "embed",
        });

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      <VideoIframe url={url} aspectRatio={"16/9"} />
    </ModuleWrapper>
  );
}
