import useSearchSettings from "@municipio/gatsby-theme-basic/src/hooks/searchSettings";
import { SkipTo } from "@whitespace/components";
import HeaderFlyOutMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderFlyOutMenu";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import HeaderMainMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderMainMenu";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import useMedia from "use-media";

import SearchForm from "../../../components/SearchForm/SearchForm";
import {
  getHamburgerHidden,
  getTopNavigationCentered,
} from "../../../hooks/header";
import { getTokenValue } from "../../../styles/tokens";

import * as defaultStyles from "./Header.module.css";
import HeaderHelpMenu from "./HeaderHelpMenu";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Header({
  styles = defaultStyles,
  className,
  children,
  ...restProps
}) {
  const { t } = useTranslation();
  const { contentNode: { isFrontPage } = {} } = usePageContext();
  const {
    themeFeatures: { enabledFeatures },
  } = useStaticQuery(graphql`
    query ThemeFeatures {
      wp {
        acfOptionsThemeOptions {
          themeFeatures {
            enabledFeatures
          }
        }
      }
    }
  `).wp.acfOptionsThemeOptions;

  const isDesktop = useMedia({
    minWidth: getTokenValue("metrics.md-breakpoint"),
  });
  const searchSettings = useSearchSettings();
  const hideSearchForm =
    isFrontPage && enabledFeatures?.includes("frontpage-hero-search");
  const hideFlyOutMenu = getHamburgerHidden();
  const isTopNavigationCentered = getTopNavigationCentered();

  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <SkipTo />

      <div className="o-container">
        <div className={clsx(styles.inner)}>
          <HeaderLogo linkTo="/" />
          <HeaderHelpMenu />
          {!hideSearchForm && (
            <SearchForm
              settings={searchSettings.searchDisplay}
              className="c-search-form c-search-form--header"
              autoFocus={false}
              id="header-search-form"
            />
          )}

          {((isDesktop && !hideFlyOutMenu) || !isDesktop) && (
            <HeaderFlyOutMenu />
          )}
        </div>
      </div>

      {isDesktop && (
        <>
          <HeaderMainMenu
            menuName="HORIZONTAL_MENU"
            className={clsx("o-container")}
            centered={isTopNavigationCentered}
            aria-label={t("mainMenu")}
          />

          <HeaderMainMenu
            menuName="SECONDARY_MENU"
            centered={true}
            className={clsx("o-container")}
            aria-label={t("secondaryMenu")}
          />
        </>
      )}

      {children}
    </header>
  );
}
