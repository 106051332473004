import { H, Section } from "@jfrk/react-heading-levels";
import useThemeSettings from "@municipio/gatsby-theme-basic/src/hooks/theme";
import { Link } from "@whitespace/components";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer({
  footerBlocks,
  footerLegalMenu,
  footerQuickNav,
  ...restProps
}) {
  const { themeFooterLogo } = useThemeSettings();

  const gridModifier = footerBlocks.length == 3 ? "footer__grid--third" : "";

  const { t } = useTranslation();
  return (
    <footer className="footer o-container" {...restProps}>
      <div className={cx("footer__grid", gridModifier)}>
        <Section>
          {footerBlocks && footerBlocks.length
            ? footerBlocks.map((block, index) => {
                if (block.fieldGroupName.includes("TextBlock")) {
                  const { textBlockTitle, textBlockContent } = block;
                  return (
                    <div className="footer__block u-hidden-print" key={index}>
                      {textBlockTitle && (
                        <H className="footer__block-title">{textBlockTitle}</H>
                      )}
                      {textBlockContent && <p><HTML>{textBlockContent}</HTML></p>}
                    </div>
                  );
                }

                if (block.fieldGroupName.includes("ContactBlock")) {
                  const {
                    contactBlockTitle,
                    contactBlockShowMore,
                    contactBlockMoreLink,
                    contactBlockAddress,
                    contactBlockPhone,
                    contactBlockEmail,
                  } = block;
                  return (
                    <div className="footer__block" key={index}>
                      <div className="footer__block-header u-hidden-print">
                        {contactBlockTitle && (
                          <H className="footer__block-title">
                            {contactBlockTitle}
                          </H>
                        )}
                      </div>
                      <dl className="footer__block-dl">
                        {contactBlockAddress && (
                          <>
                            <dt>{t("Address")}: </dt>
                            <dd>{contactBlockAddress}</dd>
                          </>
                        )}

                        {contactBlockPhone && (
                          <>
                            <dt>{t("Phone")}: </dt>
                            <dd>{contactBlockPhone}</dd>
                          </>
                        )}

                        {contactBlockEmail && (
                          <>
                            {" "}
                            <dt>{t("E-mail")}: </dt>
                            <dd>
                              <Link to={`mailto:${contactBlockEmail}`}>
                                {contactBlockEmail}
                              </Link>
                            </dd>
                          </>
                        )}
                      </dl>
                      {contactBlockShowMore && (
                        <Link
                          className="footer__block-more-link u-hidden-print"
                          to={contactBlockMoreLink?.uri}
                        >
                          {t("More contact information")}
                        </Link>
                      )}
                    </div>
                  );
                }

                if (
                  block.fieldGroupName.includes("LinksBlock") &&
                  block.linksBlockDisplay
                ) {
                  return (
                    <div className="footer__block u-hidden-print" key={index}>
                      {block.linksBlockTitle && (
                        <div className="footer__block-header">
                          <H className="footer__block-title">
                            {block.linksBlockTitle}
                          </H>
                        </div>
                      )}
                      <ul className="footer__block-link-list">
                        {footerQuickNav &&
                        footerQuickNav.items &&
                        footerQuickNav.items.length
                          ? footerQuickNav.items.map((item, linkIndex) => {
                              return (
                                <li key={linkIndex}>
                                  <Link to={item.url}>{item.label}</Link>
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  );
                }

                if (
                  block.fieldGroupName.includes("LogotypeBlock") &&
                  block.logotypeBlockLogo
                ) {
                  return (
                    <a
                      className="footer__block footer__block--logo u-hidden-print"
                      key={index}
                      href="/"
                    >
                      <img
                        src={block.logotypeBlockLogo.src}
                        alt={block.logotypeBlockLogo.altText}
                      />
                    </a>
                  );
                }
              })
            : null}
        </Section>
      </div>
      <div className="footer__contentinfo u-hidden-print">
        {footerLegalMenu &&
        footerLegalMenu.items &&
        footerLegalMenu.items.length ? (
          <ul className="footer__contentinfo-list">
            {footerLegalMenu.items.map((item, index) => (
              <li className="footer__contentinfo-list-item" key={index}>
                <a className="footer__contentinfo-link" href={item.url}>
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        ) : null}
        <div className="footer__block u-hidden-print">
          {themeFooterLogo && themeFooterLogo.sourceUrl && (
            <img
              src={themeFooterLogo.sourceUrl}
              alt=""
              className="footer__logo"
            />
          )}
        </div>
      </div>
    </footer>
  );
}
