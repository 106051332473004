import useFooterBlocks from "@municipio/gatsby-theme-basic/src/hooks/footer";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import React from "react";

import Footer from "./Footer";

export default function FooterContainer({ ...restProps }) {
  let footerLegalMenu = useMenu("FOOTER_LEGAL_MENU");
  let footerQuickNav = useMenu("FOOTER_QUICK_NAV");
  let footerBlocks = useFooterBlocks();

  return (
    <Footer
      footerBlocks={footerBlocks}
      footerLegalMenu={footerLegalMenu}
      footerQuickNav={footerQuickNav}
      {...restProps}
    />
  );
}
