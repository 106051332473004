import { PageGridLayout } from "@whitespace/components";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import React from "react";

import Article from "../components/Article";
import { EventPageContent } from "../components/EventPage";
import SEO from "../components/seo";
import { getBreadcrumbsHidden } from "../hooks/header";

export default function EventPageTemplate({ pageContext }) {
  const {
    contentNode: {
      title,
      content,
      modifiedGmt: modifiedDate,
      postMetaJSON,
      featuredImage,
      contentMedia,
    },
    isPreview,
  } = pageContext;

  const { processPageContent } = useHTMLProcessor();
  let processedContent = processPageContent(content, { contentMedia });

  return (
    <>
      <SEO title={title} />
      <PageGridLayout>
        <Article
          title={title}
          preamble={{
            text: processedContent.preamble,
          }}
          modifiedDate={modifiedDate}
          featuredImage={featuredImage}
          className="o-margin-top-large"
        >
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit c-article">
              <EventPageContent
                title={title}
                content={content}
                postMeta={JSON.parse(postMetaJSON)}
              />
              {processedContent.content}
            </div>
          </div>
        </Article>
      </PageGridLayout>
    </>
  );
}
