import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { PageGridLayout } from "@whitespace/components";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import React from "react";

import Article from "../components/Article";
import SEO from "../components/seo";

export default function PostPageTemplate({ pageContext }) {
  const {
    contentNode: {
      title,
      content,
      dateGmt: publishedDate,
      displaySettings: { postSingleShowFeaturedImage },
      featuredImage,
      modifiedGmt: modifiedDate,
      contentArea,
      sliderArea,
      categories: { nodes: categories } = {},
      tags: { nodes: tags } = {},
      contentMedia,
    },
    isPreview,
  } = pageContext;

  const { processPageContent } = useHTMLProcessor();
  let processedContent = processPageContent(content, { contentMedia });

  return (
    <>
      <SEO title={title} />
      <PageGridLayout>
        <Article
          title={title}
          preamble={{
            text: processedContent.preamble,
          }}
          publishedDate={publishedDate}
          modifiedDate={modifiedDate}
          featuredImage={postSingleShowFeaturedImage && featuredImage}
          taxonomyTerms={{ categories, tags }}
          className="o-margin-top-large"
        >
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit c-article">
              {processedContent.content}
            </div>
          </div>
          <div className="o-grid-row">
            <ModularityArea area={{ ...contentArea, displayGrid: true }} />
          </div>
        </Article>
      </PageGridLayout>
    </>
  );
}
