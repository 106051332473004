// extracted by mini-css-extract-plugin
export var bottom = "CookieConsent-module--bottom--Ygpb8";
export var bottomLeft = "CookieConsent-module--bottomLeft--qFRTv";
export var bottomRight = "CookieConsent-module--bottomRight--kC6gX";
export var button = "CookieConsent-module--button--TA1jX";
export var buttonApprove = "CookieConsent-module--button--approve--T-q04";
export var buttonDecline = "CookieConsent-module--button--decline--QD2LH";
export var buttonGroup = "CookieConsent-module--button-group--rtiYi";
export var component = "CookieConsent-module--component--nYJUw";
export var description = "CookieConsent-module--description--ByngM";
export var more = "CookieConsent-module--more--XBRgC";
export var title = "CookieConsent-module--title--+i-CL";
export var top = "CookieConsent-module--top--pka7I";
export var topLeft = "CookieConsent-module--topLeft--3H3YU";
export var topRight = "CookieConsent-module--topRight--IcFN3";