import { fallbackImage } from "@municipio/gatsby-theme-basic/src/hooks/image-settings";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";

export default function getOgGraphContent(siteMeta, initialSeo) {
  return ogGraphObject(siteMeta, initialSeo);
}

export function ogGraphObject(siteMeta, initialSeo) {
  let {
    contentNode: {
      contentType: { node: { name: contentTypeName } = {} } = {},
      ogGraph,
      content,
      description,
      featuredImage,
      uri,
    } = {},
  } = usePageContext();

  const { processPageContent, stripHTML } = useHTMLProcessor();

  if (contentTypeName) {
    const processedContent = processPageContent(content);

    if (!description) {
      if (processedContent.preamble) {
        description = processedContent.preamble;
      } else {
        description = stripHTML(content).slice(0, 300);
      }
    }

    return {
      metaTitle: ogGraph.ogTitle
        ? ogGraph.ogTitle
        : initialSeo.title
        ? initialSeo.title
        : siteMeta.title,
      metaDescription: ogGraph.ogDescription
        ? ogGraph.ogDescription
        : description
        ? description
        : siteMeta.description,
      metaImage: ogGraph.ogImage
        ? ogGraph.ogImage
        : featuredImage?.node
        ? featuredImage?.node
        : fallbackImage(),
      metaUrl: siteMeta.siteUrl + uri,
    };
  }

  return {
    metaTitle: initialSeo.title ? initialSeo.title : siteMeta.title,
    metaDescription: initialSeo.description
      ? initialSeo.description
      : siteMeta.description,
    metaImage: fallbackImage(),
    metaUrl: siteMeta.siteUrl,
  };
}
