import { useLocation } from "@gatsbyjs/reach-router";
import { H } from "@jfrk/react-heading-levels";
import { Button, Icon, withComponentDefaults } from "@whitespace/components";
import { usePrevious } from "@whitespace/gatsby-hooks";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getTreeStructure } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import clsx from "clsx";
import FocusTrap from "focus-trap-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import TreeMenu from "../../components/TreeMenu.js";
import * as treeMenuStyles from "../../components/TreeMenu.module.css";

import * as defaultStyles from "./HeaderFlyOutMenu.module.css";
import HeaderHelpMenu from "./HeaderHelpMenu";

export default withComponentDefaults(HeaderFlyOutMenu, "headerFlyOutMenu");

HeaderFlyOutMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

function HeaderFlyOutMenu({ styles = defaultStyles, className, ...restProps }) {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const allPages = usePages();
  const items = getTreeStructure(allPages);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const shortcuts = useMenu("MOBILE_HELP_MENU");

  useEffect(() => {
    if (location !== prevLocation) {
      setExpanded(false);
    }
  }, [location, prevLocation]);

  useEffect(() => {
    document.body.style.overflow = expanded ? "hidden" : null;
  }, [expanded]);

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <Button
        onClick={() => setExpanded(!expanded)}
        className={styles.toggle}
        aria-label={t("Menu")}
        aria-expanded={expanded}
      >
        <span className={clsx(styles.label)}>{t("Menu")}</span>
        <Icon name="icon-menu" />
      </Button>
      {expanded && (
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            onDeactivate: () => {
              setExpanded(false);
            },
          }}
        >
          <div>
            <div
              className={styles.backdrop}
              onClick={() => setExpanded(false)}
            />
            <div className={clsx(styles.menu)} role="dialog" aria-modal="true">
              <div className={clsx(styles.header)}>
                <H className={clsx(styles.title)}>{t("Menu")}</H>
                <Button
                  className={clsx(styles.closeBtn)}
                  aria-label="Close menu"
                  onClick={() => {
                    setExpanded(false);
                  }}
                >
                  <Icon name="icon-close" aria-label={t("close")} />
                </Button>
              </div>
              <div className={clsx(styles.content)}>
                <TreeMenu
                  styles={treeMenuStyles}
                  items={items}
                  location={location}
                />
              </div>
              {shortcuts && shortcuts.items && shortcuts.items.length && (
                <div className={clsx(styles.footer)}>
                  <HeaderHelpMenu
                    styles={{
                      component: styles.helpMenu,
                      list: styles.helpMenuList,
                      item: styles.helpMenuItem,
                      link: styles.helpMenuLink,
                      icon: styles.helpMenuIcon,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </FocusTrap>
      )}
    </div>
  );
}
