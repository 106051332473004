// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-whitespace-gatsby-plugin-site-index-src-components-site-index-template-js": () => import("./../../../../../node_modules/@whitespace/gatsby-plugin-site-index/src/components/SiteIndexTemplate.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-plugin-site-index-src-components-site-index-template-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-wp-preview-js": () => import("./../../../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/pages/wp-preview.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-wp-preview-js" */),
  "component---packages-gatsby-theme-staffanstorp-src-pages-tillganglighetsredogorelse-js": () => import("./../../../../../packages/gatsby-theme-staffanstorp/src/pages/tillganglighetsredogorelse.js" /* webpackChunkName: "component---packages-gatsby-theme-staffanstorp-src-pages-tillganglighetsredogorelse-js" */),
  "component---packages-gatsby-theme-staffanstorp-src-whitespace-gatsby-theme-wordpress-basic-pages-404-js": () => import("./../../../../../packages/gatsby-theme-staffanstorp/src/@whitespace/gatsby-theme-wordpress-basic/pages/404.js" /* webpackChunkName: "component---packages-gatsby-theme-staffanstorp-src-whitespace-gatsby-theme-wordpress-basic-pages-404-js" */),
  "component---packages-gatsby-theme-staffanstorp-src-whitespace-gatsby-theme-wordpress-basic-templates-algolia-search-template-js": () => import("./../../../../../packages/gatsby-theme-staffanstorp/src/@whitespace/gatsby-theme-wordpress-basic/templates/AlgoliaSearchTemplate.js" /* webpackChunkName: "component---packages-gatsby-theme-staffanstorp-src-whitespace-gatsby-theme-wordpress-basic-templates-algolia-search-template-js" */),
  "component---packages-gatsby-theme-staffanstorp-src-whitespace-gatsby-theme-wordpress-basic-templates-content-type-template-js": () => import("./../../../../../packages/gatsby-theme-staffanstorp/src/@whitespace/gatsby-theme-wordpress-basic/templates/ContentTypeTemplate.js" /* webpackChunkName: "component---packages-gatsby-theme-staffanstorp-src-whitespace-gatsby-theme-wordpress-basic-templates-content-type-template-js" */),
  "component---packages-gatsby-theme-staffanstorp-src-whitespace-gatsby-theme-wordpress-basic-templates-single-template-js": () => import("./../../../../../packages/gatsby-theme-staffanstorp/src/@whitespace/gatsby-theme-wordpress-basic/templates/SingleTemplate.js" /* webpackChunkName: "component---packages-gatsby-theme-staffanstorp-src-whitespace-gatsby-theme-wordpress-basic-templates-single-template-js" */)
}

