import useTaxonomies from "@municipio/gatsby-theme-basic/src/hooks/useTaxonomies";
import React from "react";

import getMostRelevantDate from "./getMostRelevantDate";

function visibleFields(item) {
  return {
    showDate: item?.includes("date"),
    showImage: item?.includes("image"),
    showExcerpt: item?.includes("excerpt"),
  };
}

export default function normalizePostsModuleItems(
  { modPostsDataSource, contentNodes, modPostsDataDisplay },
  { HTML, stripHTML } = {},
) {
  if (!modPostsDataSource?.postsDataSource) {
    return [];
  }
  const { showDate, showImage, showExcerpt } = visibleFields(
    modPostsDataDisplay.postsFields,
  );

  const postType = modPostsDataSource?.postsDataPostType?.name;

  switch (modPostsDataSource.postsDataSource) {
    case "input":
      return (modPostsDataSource.data || []).map(
        ({ postContentMedia, ...item }) => {
          let processedContent = (
            <HTML contentMedia={postContentMedia}>{item.postContent}</HTML>
          );
          return {
            ...item,
            title: item.postTitle,
            url: item.link?.url || item.permalink,
            excerpt: showExcerpt && stripHTML(item.postContent),
            content: showExcerpt && processedContent,
          };
        },
      );

    default: {
      let itemsArr = contentNodes?.nodes || [];

      let items = itemsArr.filter(Boolean).map(({ contentMedia, ...item }) => {
        let processedContent = (
          <HTML contentMedia={contentMedia}>{item.content}</HTML>
        );

        let nearestDate = null;
        if (postType === "event") {
          let postMeta = item.postMetaJSON && JSON.parse(item.postMetaJSON);
          let eventDates = postMeta?.occasions_complete?.map(
            (item) => item.start_date,
          );
          nearestDate = eventDates
            ? getMostRelevantDate(eventDates)
            : nearestDate;
        }

        return {
          ...item,
          contentType: item.contentType?.node?.name,
          title: item.title,
          dateGmt: showDate && item.dateGmt,
          date:
            showDate &&
            (nearestDate ||
              (item.archiveDatesGmt &&
                getMostRelevantDate(item.archiveDatesGmt)) ||
              item.dateGmt),
          url: item.uri,
          excerpt:
            showExcerpt &&
            (item.description ? item.description : stripHTML(item.content)),
          image: showImage && item.featuredImage?.node,
          content: showExcerpt && processedContent,
          taxonomies: useTaxonomies(
            { ...item.tags?.nodes, ...item.categories?.nodes },
            item.contentType?.node?.name,
          ),
        };
      });

      if (postType === "event") {
        items = items
          ?.filter((item) => new Date(item.date) >= new Date())
          .sort((a, b) => new Date(a.date) - new Date(b.date));
      }

      let itemsToSlice =
        modPostsDataSource.postsCount >= 0
          ? modPostsDataSource.postsCount
          : itemsArr.length;

      return items.slice(0, itemsToSlice);
    }
  }
}
