/** @jsx jsx */
import {
  // css,
  jsx,
} from "@emotion/react";
import FormFieldDescription from "@whitespace/components/dist/components/FormFieldDescription";
import FormFieldError from "@whitespace/components/dist/components/FormFieldError";
import FormFieldWrapper from "@whitespace/components/dist/components/FormFieldWrapper";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import SelectField from "../../../components/SelectField";
import FormFieldLabel from "../FormFieldLabel";

import selectStyles from "./FormReactSelectFieldStyles";

FormSelectField.propTypes = {
  className: PropTypes.string,
  fieldProps: PropTypes.object,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function FormSelectField({
  className,
  fieldProps,
  multiple,
  options,
  styles = {},
  ...restProps
}) {
  return (
    <FormFieldWrapper
      className={clsx(styles.component, className)}
      {...restProps}
    >
      {({ name, controlProps }) => (
        <React.Fragment>
          <FormFieldLabel className={styles.label} />
          <FormFieldDescription className={styles.description} />
          <FormFieldError className={styles.error} />
          <div className={styles.control} {...controlProps}>
            <SelectField
              className={styles.select}
              name={name}
              id={name}
              multiple={multiple}
              {...fieldProps}
              styles={selectStyles}
              options={options}
              placeholder="Välj ett alternativ"
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? "Inga fler alternativ" : "Inga resultat hittades"
              }
            />
          </div>
        </React.Fragment>
      )}
    </FormFieldWrapper>
  );
}
