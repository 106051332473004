import { H } from "@jfrk/react-heading-levels";
import { Link } from "@whitespace/components";
import cx from "classnames";
import React from "react";

import Button from "../Button/Button";
import FluidImage from "../Image/FluidImage";

export default function BoxNavigationWithImage({
  image,
  title,
  preamble,
  link,
  items,
  ...restProps
}) {
  return (
    <div
      className="c-box-navigation c-box-navigation--w-image c-box-navigation--split"
      {...restProps}
    >
      {image && (
        <FluidImage
          base64=""
          src={image.src}
          srcSet={image.srcSet}
          aspectRatio={16 / 9}
          width={image.width}
          height={image.height}
          alt={image.altText}
          className="o-grid-block o-grid-block--100 c-box-navigation__image"
        />
      )}
      <div
        className={cx(
          "c-box-navigation__content",
          !image && "c-box-navigation__content--single",
        )}
      >
        {title || preamble ? (
          <div className="c-box-navigation__header">
            {title ? (
              link ? (
                // <Link className="c-box-navigation__title" to={link}>
                //   {title}
                //   <Icon />
                // </Link>
                <Button
                  className="c-box-navigation__title c-box-navigation__title--w-link"
                  title={title}
                  link={link}
                  iconAfterName="arrow-right-1"
                  iconAfter={true}
                  buttonModifier="prominent-link"
                  iconModifier="primary"
                  iconBgColor="var(--color-action)"
                  roundIcon={true}
                  iconAfterSize="24px"
                />
              ) : (
                <H className="c-box-navigation__title">{title}</H>
              )
            ) : null}
            {preamble ? (
              <p className="c-box-navigation__preamble">{preamble}</p>
            ) : null}
          </div>
        ) : null}
        {items && items.length ? (
          <div className="c-box-navigation__body u-hidden-print">
            <ul className="c-box-navigation__list">
              {items.map((item, index) => {
                return (
                  <li key={index} className="c-box-navigation__item">
                    <Link
                      className="c-box-navigation__link c-button c-button--theme"
                      to={item.uri}
                    >
                      {item.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
}
