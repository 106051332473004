import { css } from "@emotion/react";
import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { PageBreadcrumbs } from "@whitespace/gatsby-plugin-breadcrumbs";
import CookieConsent from "@whitespace/gatsby-plugin-cookie-consent/src/components";
import useCookieConsentSettings from "@whitespace/gatsby-plugin-cookie-consent/src/hooks/useCookieConsentSettings";
import Header from "@whitespace/gatsby-theme-wordpress-basic/src/components/Header";
import * as styles from "@whitespace/gatsby-theme-wordpress-basic/src/components/SiteLayout.module.css";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import clsx from "clsx";
import { kebabCase } from "lodash";
import React from "react";
import Helmet from "react-helmet";

import Alert from "../../../components/Alert";
import Footer from "../../../components/Footer";
import FooterShortcuts from "../../../components/FooterShortcuts";
import {
  HeaderFrontPageSearch,
  HeaderFrontPageMenu,
} from "../../../components/Header";
import Hero from "../../../components/Hero/Hero";
import SiteBanner from "../../../components/SiteBanner";
import { getBreadcrumbsHidden } from "../../../hooks/header";

export default function SiteLayout({ children }) {
  const {
    contentNode: {
      databaseId,
      isFrontPage,
      sliderArea,
      hero,
      pageAppearance: { template } = {},
      pageThemeColor: { theme = [] } = {},
    } = {},
  } = usePageContext();
  const { position, active, strings } = useCookieConsentSettings();

  let className;
  let restProps;
  let mainRestProps;

  const showBreadcrumbs =
    !getBreadcrumbsHidden() && template !== "landingPage" && !isFrontPage;

  const themeProp = theme ? kebabCase(theme) : null;

  return (
    <div
      css={
        themeProp &&
        css`
          --page-theme-color-muted: var(--brand-color-${themeProp});
          --box-navigation-link-background: var(--brand-color-${themeProp});
          --box-navigation-link-hover-color: var(--brand-color-${themeProp}-text);
          --box-navigation-link-hover-background: var(--brand-color-${themeProp});
          );
        `
      }
      className={className}
      {...restProps}
    >
      <Helmet
        htmlAttributes={{ lang: "sv-SE" }}
        meta={[
          {
            name: "edit-url",
            content: `${process.env.GATSBY_WORDPRESS_URL}/wp-admin/post.php?post=${databaseId}&action=edit`,
          },
        ]}
      />
      <SiteBanner />
      <Header>
        {sliderArea && <ModularityArea area={sliderArea} />}
        {hero && hero.heroImage !== null && (
          <Hero image={hero.heroImage} text={hero.text} />
        )}
        {isFrontPage && (
          <>
            <HeaderFrontPageSearch />
            <HeaderFrontPageMenu />
          </>
        )}
      </Header>
      <main id="main" className={styles.main} {...mainRestProps}>
        {!process.env.GATSBY_DISABLE_ALERT && (
          <Alert className="o-section--full-width" />
        )}

        <div
          className={clsx(
            styles.breadcrumbs,
            "u-hidden-print",
            !showBreadcrumbs && "u-visually-hidden",
          )}
        >
          {showBreadcrumbs && (
            <PageBreadcrumbs className="o-container o-padding-small" />
          )}
        </div>
        {children}
      </main>
      <FooterShortcuts />
      <Footer />
      <CookieConsent
        position={position}
        cookieConsentSettings={strings}
        active={active}
      />
    </div>
  );
}
