import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import clsx from "clsx";
import React from "react";

import useOperationalStatuses from "../../hooks/operationalStatus";
import Card from "../Card/Card";
import CardOperational from "../Card/CardOperational";

export default function DisturbancesModule({ title, module, ...restProps }) {
  const { disturbances: { otherCards, showOperationalStatus } = {} } = module;
  return (
    <ModuleWrapper
      title={({ H }) => <H className="c-disturbances-module__title">{title}</H>}
      className="c-disturbances-module"
      {...restProps}
    >
      <div className="o-grid c-disturbances-module__wrapper">
        {showOperationalStatus && (
          <CardOperational
            className="c-card--style-1 c-card--radius"
            postType="operational-status"
            items={useOperationalStatuses()}
          />
        )}
        {otherCards &&
          otherCards.map(({ title, link, content }, key) => {
            let cardStyle =
              key % 2 == 0 ? "c-card--style-2" : "c-card--style-3";
            return (
              <Card
                key={key}
                className={clsx("c-card--radius", cardStyle)}
                title={title}
                excerpt={content}
                url={link && link.url}
              />
            );
          })}
      </div>
    </ModuleWrapper>
  );
}
