/** @jsx jsx */
import {
  // css,
  jsx,
} from "@emotion/react";
import { normalizeOptions } from "@whitespace/gatsby-plugin-search/src/utils";
import { useField } from "formik";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
// import { useSelectStyles } from "../hooks";

SelectField.propTypes = {
  multiple: PropTypes.bool,
  options: PropTypes.any,
};

export default function SelectField(props) {
  const [{ onChange, value, ...field }, , { setValue }] = useField(props);

  let { multiple, options, ...restProps } = props;

  let normalizedOptions = normalizeOptions(options);

  if (multiple) {
    normalizedOptions = normalizedOptions.filter(
      (option) => option.value !== "",
    );
  }

  return (
    <ReactSelect
      {...field}
      {...restProps}
      options={normalizedOptions}
      isMulti={multiple}
      value={
        Array.isArray(value)
          ? value.map((value) =>
              normalizedOptions.find((option) => option.value === value),
            )
          : normalizedOptions.find((option) => option.value === value)
      }
      onChange={
        onChange &&
        ((newValue) => {
          setValue(
            Array.isArray(newValue)
              ? newValue.map((option) =>
                  typeof option === "object" ? option.value : option,
                )
              : typeof newValue === "object"
              ? newValue.value
              : newValue,
          );
        })
      }
    />
  );
}
