import { H, Section } from "@jfrk/react-heading-levels";
import { Link } from "@whitespace/components";
import cx from "classnames";
import React from "react";

import Icon from "../../Icon/Icon";

export default function EventSectionInformation({
  organizers,
  accessibility,
  ageGroup,
  className,
  ...restProps
}) {
  return (
    <div className={cx("event__details", className)} {...restProps}>
      {organizers && organizers.length ? (
        <div>
          <H className="event__title">
            <Icon name="briefcase" size="20" className="event__title-icon" />
            Arrangör
          </H>
          <Section>
            <ul>
              {organizers.map(
                (
                  {
                    organizer,
                    organizer_email,
                    organizer_link,
                    organizer_phone,
                  },
                  index,
                ) => {
                  return (
                    <li key={index}>
                      {organizer ? <p>{organizer}</p> : null}
                      {organizer_phone ? <p>{organizer_phone}</p> : null}
                      {organizer_email ? <p>{organizer_email}</p> : null}
                      {organizer_link ? (
                        <Link
                          to={organizer_link}
                          className={"c-link"}
                          id="more-date"
                        >
                          Mer information om evenemanget
                        </Link>
                      ) : null}
                    </li>
                  );
                },
              )}
            </ul>
          </Section>
        </div>
      ) : null}
      {accessibility && accessibility.length ? (
        <div>
          <H className="event__title">
            <Icon
              name="disability-wheelchair"
              size="20"
              className="event__title-icon"
            />
            Tillgänglighet
          </H>
          <Section>
            <ul>
              {accessibility.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Section>
        </div>
      ) : null}
      {ageGroup ? (
        <div>
          <H className="event__title">
            <Icon
              name="family-child-hold-hand"
              size="20"
              className="event__title-icon"
            />
            Ålder
          </H>
          <Section>
            <p>
              {ageGroup.from}-{ageGroup.to} år
            </p>
          </Section>
        </div>
      ) : null}
    </div>
  );
}
