import { Section } from "@jfrk/react-heading-levels";
import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { PageGridLayout, PageGrid, PageGridItem } from "@whitespace/components";
import { BoxNavigation } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import {
  getChildren,
  getSiblings,
} from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import React from "react";

import Article from "../components/Article";
import SEO from "../components/seo";

export default function InfoPageTemplate({ pageContext }) {
  const {
    contentNode: {
      id: pageId,
      title,
      featuredImage,
      contentArea,
      content,
      displaySettings: { postSingleShowFeaturedImage },
      modifiedGmt: modifiedDate,
      managedBy: { managedBy },
      contentMedia,
    },
    isPreview,
  } = pageContext;

  const { processPageContent } = useHTMLProcessor();
  let processedContent = processPageContent(content, { contentMedia });

  const allPages = usePages();

  const childPages = getChildren(allPages, pageId)
    .filter((page) => page.showInMenu)
    .map((page) => ({
      ...page,
      preamble: page.description,
      items: getChildren(allPages, page.id)
        .filter((page) => page.showInMenu)
        .map((page) => ({
          ...page,
        })),
    }));

  const relatedPages = getSiblings(allPages, pageId).map((page) => ({
    title: page.label,
    uri: page.uri,
  }));

  return (
    <>
      <SEO title={title} />
      <PageGridLayout>
        <Article
          title={title}
          preamble={{
            text: processedContent.preamble,
          }}
          childPages={childPages}
          featuredImage={postSingleShowFeaturedImage && featuredImage}
          // publishedDate={publishedDate}
          modifiedDate={modifiedDate}
          managedBy={managedBy}
          className="o-margin-top-large"
        >
          <PageGridItem className="c-article">
            {processedContent.content}
          </PageGridItem>
          <ModularityArea area={{ ...contentArea, displayGrid: true }} />
        </Article>
        <PageGrid>
          {relatedPages && !!relatedPages.length && (
            <Section>
              <PageGridItem>
                <BoxNavigation
                  title="Relaterade sidor"
                  items={relatedPages}
                  className="o-margin-top-medium"
                />
              </PageGridItem>
            </Section>
          )}
        </PageGrid>
      </PageGridLayout>
    </>
  );
}
