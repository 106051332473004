import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./SiteBanner.module.css";

SiteBanner.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function SiteBanner({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const {
    siteBanner: { text },
  } = useStaticQuery(graphql`
    query SiteBanner {
      wp {
        acfOptionsThemeOptions {
          siteBanner {
            text
          }
        }
      }
    }
  `).wp.acfOptionsThemeOptions;

  if (!text || !text.length) return null;

  return (
    <div
      className={clsx(styles.component, "o-container", className)}
      {...restProps}
    >
      <div className={clsx(styles.wrapper)}>
        <HTML>{text}</HTML>
      </div>
    </div>
  );
}
