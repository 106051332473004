import { H } from "@jfrk/react-heading-levels";
// import { Button } from "@whitespace/components";
import cx from "classnames";
import React from "react";

import ExpandableList from "../ExpandableList/ExpandableList";

import EventBooking from "./EventPageSections/EventSectionBooking";
// import EventContact from "./EventPageSections/EventSectionContact";
import EventDates from "./EventPageSections/EventSectionDates";
import EventDetails from "./EventPageSections/EventSectionDetails";
import EventInformation from "./EventPageSections/EventSectionInformation";
import EventLinks from "./EventPageSections/EventSectionLinks";
import EventPlace from "./EventPageSections/EventSectionPlace";
// import EventSectionGallery from "./EventPageSections/EventSectionGallery";

export default function EventPageContent({
  postMeta,
  className,
  ...restProps
}) {
  const {
    accessibility,
    contact_email,
    contact_information,
    location: defaultLocation,
    occasions_complete: occasions,
    organizers,
    status,
    categories,
  } = postMeta;

  // Booking and tickets
  const {
    additional_ticket_retailers,
    additional_ticket_types,
    age_restriction,
    booking_email,
    booking_group,
    booking_link,
    booking_phone,
    children_age,
    membership_cards,
    price_adult,
    price_children,
    price_information,
    price_range,
    price_senior,
    price_student,
    senior_age,
    ticket_includes,
    ticket_release_date,
    ticket_stock,
    tickets_remaining,
  } = postMeta;

  // Links
  const {
    apple_music,
    deezer,
    facebook,
    google_music,
    instagram,
    soundcloud,
    spotify,
    twitter,
    vimeo,
    youtube,
  } = postMeta;

  const nextOccasion =
    occasions.find(
      (occasion) =>
        new Date(occasion.end_date || occasion.start_date) > new Date(),
    ) || occasions[occasions.length - 1];

  const location =
    defaultLocation || nextOccasion.location
      ? {
          ...defaultLocation,
          ...(nextOccasion && nextOccasion.location),
        }
      : false;

  // const cats = categories.split(/\r?\n/);

  return (
    <div className={cx("event", className)} {...restProps}>
      <H className="u-visually-hidden">Information om evenemanget</H>
      {/* <Button to="/registrera-evenemang">Registrera evenemang</Button> */}
      <EventDetails
        occasion={nextOccasion}
        location={location}
        status={status}
        categories={categories}
      />

      <ExpandableList
        items={[
          (organizers || accessibility) && {
            title: "Information",
            content: (
              <EventInformation
                organizers={organizers}
                accessibility={accessibility}
                // ageGroup={age_group}
              />
            ),
          },
          location && {
            title: "Plats",
            content: <EventPlace location={location} />,
          },
          occasions &&
            occasions.length > 1 && {
              title: "Datum",
              content: <EventDates occasions={occasions} />,
            },
          (contact_email || contact_information) && {
            title: "Kontakt",
            content: (
              <div className="event__details">
                <address className="event__contact">
                  {contact_information}
                  <br />
                  {contact_email}
                </address>
              </div>
            ),
          },
          ticket_release_date && {
            title: "Biljetter",
            content: (
              <EventBooking
                {...{
                  additional_ticket_retailers,
                  additional_ticket_types,
                  age_restriction,
                  booking_email,
                  booking_group,
                  booking_link,
                  booking_phone,
                  children_age,
                  membership_cards,
                  price_adult,
                  price_children,
                  price_information,
                  price_range,
                  price_senior,
                  price_student,
                  senior_age,
                  ticket_includes,
                  ticket_release_date,
                  ticket_stock,
                  tickets_remaining,
                }}
              />
            ),
          },
          (apple_music ||
            deezer ||
            facebook ||
            google_music ||
            instagram ||
            soundcloud ||
            spotify ||
            twitter ||
            vimeo ||
            youtube) && {
            title: "Länkar",
            content: (
              <EventLinks
                {...{
                  apple_music,
                  deezer,
                  facebook,
                  google_music,
                  instagram,
                  soundcloud,
                  spotify,
                  twitter,
                  vimeo,
                  youtube,
                }}
              />
            ),
          },
        ].filter(Boolean)}
      />
    </div>
  );
}
