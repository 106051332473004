import { H } from "@jfrk/react-heading-levels";
import { Time } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import cx from "classnames";
import React from "react";

import { useTaxonomies } from "../../hooks/taxonomy";
import * as styles from "../Article.module.css";

export default function ArticleFooter({
  modifiedDate,
  managedBy,
  className,
  taxonomyTerms,
  ...restProps
}) {
  let taxonomies = useTaxonomies();
  return (
    <footer className={cx("c-article__footer", className)} {...restProps}>
      {modifiedDate && (
        <p>
          <span className="c-article__footer-description">
            Senast uppdaterad:
          </span>{" "}
          <Time
            date={modifiedDate}
            ago={true}
            format={{
              year: "numeric",
              month: "numeric",
              day: "numeric",
            }}
          />
        </p>
      )}
      {managedBy && (
        <p>
          <span className="c-article__footer-description">Sidansvarig:</span>{" "}
          {managedBy}
        </p>
      )}
      {taxonomyTerms
        ? taxonomies.map((taxonomy) => {
            if (
              !taxonomyTerms[taxonomy.name] ||
              taxonomyTerms[taxonomy.name].length === 0
            ) {
              return null;
            }
            return (
              <>
                <H className={styles.taxonomyTermsHeading}>
                  {taxonomy.label.plural}
                </H>
                <ul className={styles.taxonomyTermsList}>
                  {taxonomyTerms[taxonomy.name].map((term) => {
                    return (
                      <li
                        className={styles.taxonomyTermsListItem}
                        key={term.slug}
                      >
                        {term.name}
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          })
        : null}
    </footer>
  );
}
