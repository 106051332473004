import cx from "classnames";
import React from "react";

import { FluidImage } from "../Image";

export default function Hero({ image, text }) {
  return (
    <div className="c-hero o-section--full-width">
      <FluidImage
        base64={image.base64}
        src={image.src}
        srcSet={image.srcSet}
        srcWebp={image.srcWebp}
        srcSetWebp={image.srcSetWebp}
        aspectRatio={image.aspectRatio}
        width={image.width}
        height={image.height}
        alt={image.altText}
        className={cx("c-hero__image")}
      />
      {text && (
        <div className="c-hero__text">
          <p>{text}</p>
        </div>
      )}
    </div>
  );
}
