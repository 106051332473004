import { H, Section } from "@jfrk/react-heading-levels";
import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { PageGrid, PageGridLayout } from "@whitespace/components";
import { useIsMultisite } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/multisite";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";

import SEO from "../components/seo";

export default function StartPageTemplate({ pageContext }) {
  const {
    contentNode: { title, contentArea },
    isPreview,
  } = pageContext;
  const isMultisite = useIsMultisite();

  const siteTitle = useStaticQuery(graphql`
    query MyQuery2 {
      site {
        siteMetadata {
          title
        }
      }
    }
  `).site.siteMetadata.title;

  return (
    <>
      <SEO title={title} />
      <H className="u-visually-hidden">{siteTitle}</H>
      <Section>
        <PageGridLayout className="o-margin-top-large o-margin-bottom-large">
          <PageGrid>
            <ModularityArea
              area={{
                ...contentArea,
                displayGrid: true,
                defaultModuleColSpan: 12,
              }}
            />
          </PageGrid>
        </PageGridLayout>
      </Section>
    </>
  );
}
