import { H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import Button from "./Button/Button.js";

export default function SectionHeader({
  sectionHeader = {},
  title,
  titleModifier,
  description,
  withBorder,
  noMarginBottom,
  className,
  ...restProps
}) {
  return (
    <div
      className={cx(
        "c-section-header",
        withBorder && "c-section-header--border",
        !noMarginBottom && "c-section-header--margin-bottom",
        className,
      )}
      {...restProps}
    >
      <div className="c-section-header__wrapper">
        {title && (
          <H className={cx("c-section-header__title", titleModifier)}>
            {title}
          </H>
        )}
        {sectionHeader && sectionHeader.link && sectionHeader.link.url && (
          <Button
            className="c-section-header__link"
            title={sectionHeader.link.text}
            link={sectionHeader.link.url}
            iconAfterName={sectionHeader.link.icon}
            iconAfter={true}
            buttonModifier="prominent-link"
            iconModifier="primary"
            iconBgColor="var(--color-primary)"
            roundIcon={true}
            iconAfterSize="24px"
          />
        )}
      </div>
      {description && (
        <div className="c-section-header__description">
          <p>{description}</p>
        </div>
      )}
    </div>
  );
}
