import { forEach, isEmpty, isArray } from "lodash";

export default function toFormData(
  data,
  formData = new FormData(),
  parentKey = null,
) {
  console.log(data);
  if (data == null) return; // else "null" and "undefined" will be added

  if (data instanceof File) {
    if (isEmpty(parentKey)) {
      throw new Error("Cannot convert File to FormData without a parentKey");
    }
    formData.set(parentKey, data);
  } else if (isArray(data)) {
    if (isEmpty(parentKey)) {
      throw new Error("Cannot convert array to FormData without a parentKey");
    }
    forEach(data, (ele, key) => {
      toFormData(ele, formData, `${parentKey}[${key}]`);
    });
  } else if (data instanceof Object) {
    forEach(data, (value, key) => {
      let formattedKey = isEmpty(parentKey) ? key : `${parentKey}[${key}]`;
      toFormData(value, formData, formattedKey);
    });
  } else {
    if (isEmpty(parentKey)) {
      throw new Error(
        `Cannot convert ${typeof data} to FormData without a parentKey`,
      );
    }
    formData.set(parentKey, data);
  }
  return formData;
}
