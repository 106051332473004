import { H } from "@jfrk/react-heading-levels";
import { Link } from "@whitespace/components";
import { Time } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import cx from "classnames";
import React from "react";

export default function EventSectionBooking({
  additional_ticket_retailers,
  additional_ticket_types,
  age_restriction,
  booking_email,
  booking_group,
  booking_link,
  booking_phone,
  children_age,
  membership_cards,
  price_adult,
  price_children,
  price_information,
  price_range,
  price_senior,
  price_student,
  senior_age,
  ticket_includes,
  ticket_release_date,
  ticket_stock,
  tickets_remaining,
  className,
  ...restProps
}) {
  return (
    <div className={cx("event__details", className)} {...restProps}>
      {booking_link ? (
        <Link to={booking_link} className={"c-link"}>
          Länk till bokning
        </Link>
      ) : null}
      <dl className="event_list">
        {booking_phone && (
          <>
            <dt className="event_list-term">Telefon:</dt>
            <dd className="event_list-definition">{booking_phone}</dd>
          </>
        )}
        {booking_email && (
          <>
            {" "}
            <dt className="event_list-term">E-postadress:</dt>
            <dd className="event_list-definition">
              <Link to={`mailto:${booking_email}`} className={"c-link"}>
                {booking_email}
              </Link>
            </dd>{" "}
          </>
        )}
        {ticket_release_date && (
          <>
            <dt className="event_list-term">Biljettsläpp:</dt>
            <dd className="event_list-definition">
              <Time
                date={ticket_release_date}
                format={{
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                }}
              />
            </dd>{" "}
          </>
        )}
        {age_restriction && (
          <>
            <dt className="event_list-term">Lägsta ålder:</dt>
            <dd className="event_list-definition">{age_restriction} år</dd>{" "}
          </>
        )}
        {ticket_stock && (
          <>
            {" "}
            <dt className="event_list-term">Antal biljetter:</dt>
            <dd className="event_list-definition">
              {ticket_stock} stycken
            </dd>{" "}
          </>
        )}
        {tickets_remaining && (
          <>
            <dt className="event_list-term">Återstående biljetter:</dt>
            <dd className="event_list-definition">
              {tickets_remaining} stycken
            </dd>{" "}
          </>
        )}
        {additional_ticket_retailers && (
          <>
            <H className="event_list-term">Fler återförsäljare</H>
            <ul className="event_list-definition">
              {additional_ticket_retailers.map(
                (
                  {
                    retailer_name,
                    booking_url,
                    ticket_release_date,
                    ticket_stop_date,
                  },
                  index,
                ) => {
                  return (
                    <li key={index}>
                      {retailer_name && <p>{retailer_name}</p>}
                      {booking_url && (
                        <Link to={booking_url} className={"c-link"}>
                          Länk till återförsäljare
                        </Link>
                      )}
                      {ticket_release_date && (
                        <p>
                          Datum för biljettsläpp:{" "}
                          <Time
                            date={ticket_release_date}
                            format={{
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            }}
                          />
                        </p>
                      )}
                      {ticket_stop_date && (
                        <p>
                          Slutdatum för biljettsläpp:{" "}
                          <Time
                            date={ticket_stop_date}
                            format={{
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            }}
                          />
                        </p>
                      )}
                    </li>
                  );
                },
              )}
            </ul>
          </>
        )}
      </dl>
      {membership_cards && (
        <>
          <p> Ingår i medlemskort [Lista?]</p>
          <ul>
            {membership_cards.map((membershipCard, index) => (
              <li key={index}>{membershipCard}</li>
            ))}
          </ul>
        </>
      )}
      <H>Entré och biljettpriser</H>
      {price_adult && <p>Standardpris/Vuxen: {price_adult} kr</p>}
      {price_student && <p>Studentpris: {price_student} kr</p>}
      {price_children && (
        <p>
          Barnpris [
          {children_age && `Åldersgräns för barnpris: ${children_age} år`}]:{" "}
          {price_children} kr
        </p>
      )}
      {price_senior && (
        <p>
          Pensionärspris [
          {senior_age && `Åldersgräns för pensionsnärspris: ${senior_age} år`}
          ]: {price_senior} kr
        </p>
      )}
      {additional_ticket_types && (
        <>
          {" "}
          <H>Fler biljettyper</H>
          <ul>
            {" "}
            {additional_ticket_types.map(
              (
                { ticket_name, maximum_price, minimum_price, ticket_type },
                index,
              ) => (
                <li key={index}>
                  {ticket_name && <p>Biljettnamn: {ticket_name}</p>}
                  {maximum_price && <p>Maximipris: {maximum_price} kr</p>}
                  {minimum_price && <p>Minimipris: {minimum_price} kr</p>}
                  {ticket_type && <p>Typ av biljett: {ticket_type}</p>}
                </li>
              ),
            )}
          </ul>
        </>
      )}

      {price_range && (
        <>
          {" "}
          <H>Prisintervall</H>
          <ul>
            <li>
              {price_range.seated_minimum_price && (
                <p>
                  Minimipris för sittande: {price_range.seated_minimum_price} kr
                </p>
              )}
              {price_range.seated_maximum_price && (
                <p>
                  Maximipris för sittande: {price_range.seated_maximum_price} kr
                </p>
              )}
              {price_range.standing_minimum_price && (
                <p>
                  Minimipris för stående: {price_range.standing_minimum_price}{" "}
                  kr
                </p>
              )}
              {price_range.standing_maximum_price && (
                <p>
                  Maximipris för stående: {price_range.standing_maximum_price}{" "}
                  kr
                </p>
              )}
            </li>
          </ul>
        </>
      )}

      {booking_group && (
        <>
          {" "}
          <H>Grupppriser</H>
          <ul>
            {booking_group.map(
              ({ price_group, min_persons, max_persons }, index) => {
                return (
                  <li key={index}>
                    <p>Pris för grupp: {price_group} kr</p>
                    <p>Minimum antal: {min_persons}</p>
                    <p>Maximum antal: {max_persons}</p>
                  </li>
                );
              },
            )}
          </ul>{" "}
        </>
      )}
      {price_information && (
        <>
          <p>Prisinformation</p>
          <p>{price_information}</p>
        </>
      )}

      {ticket_includes && (
        <>
          <p>Ingår i biljett</p>
          <p>{ticket_includes}</p>
        </>
      )}
    </div>
  );
}
