import { H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../Button/Button";

export default function LandingPageHero({
  title,
  shortcuts,
  serviceLink,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  return (
    <div
      className={cx(
        "c-hero-butler",
        className,
        "o-section--full-width o-container u-hidden-print",
      )}
      {...restProps}
    >
      <div className="c-hero-butler__wrapper">
        <H className="c-hero-butler__title">{title}</H>
        <div className="c-hero-butler__items" {...restProps}>
          <p className="u-visually-hidden" id="shortcuts">
            {t("Shortcuts")}:
          </p>
          <ul aria-describedby="shortcuts" className="o-stack--inline">
            {serviceLink && serviceLink.url ? (
              <li className="c-hero-butler__service">
                <Button
                  iconBefore={true}
                  iconBeforeName="network-phone"
                  iconBeforeSize="24px"
                  title={serviceLink.title}
                  link={serviceLink.url}
                  buttonModifier="secondary"
                />
              </li>
            ) : null}
            {shortcuts
              ? shortcuts.map(({ title, url }, index) => {
                  return (
                    <li key={index} className="">
                      <Button
                        title={title}
                        className="c-button--theme"
                        link={url}
                      />
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
}
