import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import PageBreadcrumbs from "@whitespace/gatsby-theme-wordpress-basic/src/components/PageBreadcrumbs";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import { PageContextProvider } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import cx from "classnames";
import React from "react";

import Article from "../components/Article";
import SEO from "../components/seo";
import { getBreadcrumbsHidden } from "../hooks/header";

export default function OperationalStatusPageTemplate({ pageContext }) {
  const {
    contentNode: {
      databaseId,
      title,
      content,
      dateGmt: publishedDate,
      displaySettings: { postSingleShowFeaturedImage },
      featuredImage,
      modifiedGmt: modifiedDate,
      contentArea,
      sliderArea,
      operationalStatusCategories: { nodes: operationalStatusCategories } = {},
      contentMedia,
    },
  } = pageContext;

  const { processPageContent } = useHTMLProcessor();
  let processedContent = processPageContent(content, { contentMedia });

  const isBreadcrumbsHidden = getBreadcrumbsHidden();

  return (
    <>
      <SEO title={title} />
      {!isBreadcrumbsHidden && <PageBreadcrumbs />}
      <Article
        title={title}
        preamble={{
          text: processedContent.preamble,
        }}
        publishedDate={publishedDate}
        modifiedDate={modifiedDate}
        featuredImage={postSingleShowFeaturedImage && featuredImage}
        taxonomyTerms={{ operationalStatusCategories }}
        className="o-margin-top-large"
      >
        <div className="o-grid-row">
          <div className="o-grid-block o-grid-block--inherit c-article">
            {processedContent.content}
          </div>
        </div>
        <div className="o-grid-row">
          <ModularityArea area={{ ...contentArea, displayGrid: true }} />
        </div>
      </Article>
    </>
  );
}
