import { useStaticQuery, graphql } from "gatsby";

export default function useHeader() {
  return useStaticQuery(graphql`
    query headerSettings {
      wp {
        acfOptionsHeader {
          headerOptions {
            hideHamburger
            hideBreadcrumbs
            centerTopNavigation
          }
        }
      }
    }
  `).wp.acfOptionsHeader;
}

export function getHamburgerHidden() {
  return useHeader().headerOptions.hideHamburger;
}

export function getBreadcrumbsHidden() {
  return useHeader().headerOptions.hideBreadcrumbs;
}

export function getTopNavigationCentered() {
  return useHeader().headerOptions.centerTopNavigation;
}
