/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import PropTypes from "prop-types";

DevNotice.propTypes = {
  children: PropTypes.node,
};

export default function DevNotice({ children, ...restProps }) {
  if (process.env.NODE_ENV === "production") {
    return null;
  }
  return (
    <div
      {...restProps}
      css={css`
        background-color: #ddd;
        padding: 1rem;
      `}
    >
      {children}
    </div>
  );
}
