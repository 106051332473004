import { useStaticQuery, graphql } from "gatsby";

export default function useFooterBlocks() {
  return useStaticQuery(graphql`
    query FooterBlocks_Staffanstorp {
      wp {
        acfOptionsFooter {
          footerBlocks {
            blocks {
              ... on WP_AcfOptionsFooter_Footerblocks_Blocks_TextBlock {
                fieldGroupName
                textBlockContent
                textBlockTitle
              }
              ... on WP_AcfOptionsFooter_Footerblocks_Blocks_ContactBlock {
                fieldGroupName
                contactBlockAddress
                contactBlockEmail
                contactBlockMoreLink {
                  ... on WP_Page {
                    uri
                    title
                  }
                  ... on WP_Post {
                    uri
                    title
                  }
                }
                contactBlockPhone
                contactBlockShowMore
                contactBlockTitle
              }
              ... on WP_AcfOptionsFooter_Footerblocks_Blocks_LinksBlock {
                fieldGroupName
                linksBlockTitle
                linksBlockDisplay
              }
            }
          }
        }
      }
    }
  `).wp.acfOptionsFooter.footerBlocks.blocks;
}
