import { Link } from "@whitespace/components";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import useMedia from "use-media";

import { getTokenValue } from "../../styles/tokens";
import Icon from "../Icon/Icon";

export default function FooterShortcuts({ shortcuts, ...restProps }) {
  const { t } = useTranslation();
  const isDesktop = useMedia({
    minWidth: getTokenValue("metrics.lg-breakpoint"),
  });

  return (
    <nav
      className={cx("c-shortcuts", "o-container", "u-hidden-print")}
      aria-label={t("Shortcuts")}
      {...restProps}
    >
      <ul
        className={cx(
          "c-shortcuts__list",
          isDesktop ? "o-section" : "o-section--full-width",
        )}
      >
        {shortcuts.map((shortcut, index) => {
          return (
            <li key={index} className="c-shortcuts__item">
              <Link className="c-shortcuts__link" to={shortcut.url}>
                {shortcut.icon && shortcut.icon !== "inherit" && (
                  <Icon name={shortcut.icon} />
                )}
                {shortcut.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
