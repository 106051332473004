const styles = {
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor:
        state.isFocused || state.isSelected
          ? "var(--select-field-option-active-background-color, #004071)"
          : "var(--select-field-option-background-color, #fffff)",
      borderBottom: "1px solid var(--select-field-divider-color, #E4E4E7)",
      color:
        state.isFocused || state.isSelected
          ? "var(--select-field-option-active-color, #ffffff)"
          : "var(--select-field-option-color, #212121)",
      padding: "1rem",
      "&:hover": {
        backgroundColor:
          "var(--select-field-option-hover-background-color, #004071)",
        color: "var(--select-field-option-hover-color, #ffffff)",
      },
      whiteSpace: "normal",
      overflowWrap: "break-word",
      hyphens: "auto",
      wordWrap: "break-word",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      padding: 0,
      fontSize: "var(--select-field-menu-font-size, 0.875rem)",
    };
  },
  control: (provided) => {
    return {
      ...provided,
      borderColor: "var(--select-field-control-border-color, #949494)",
      color: "var(--select-field-control-color, #212121)",
      fontSize: "var(--select-field-font-size, 1rem)",
      fontWeight: "var(--select-field-font-weight, 400)",
      padding: "calc(1rem - 6px)",
      borderRadius: "0",
    };
  },
  indicatorSeparator: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "var(--select-field-divider-color, #eaeaea)",
      display: state.isMulti ? "flex" : "none",
    };
  },
  clearIndicator: (provided) => ({
    ...provided,
    color: "var(--select-field-clear-indicator-color, #1268b0)",
    "&:hover": {
      color: "var(--select-field-clear-indicator-hover-color, #002b4b)",
      cursor: "pointer",
    },
  }),
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: "var(--select-field-dropdown-color, #212121)",
      "&:hover": {
        color: "var(--select-field-dropdown-hover-color, #1268b0)",
        cursor: "pointer",
      },
    };
  },
  // placeholder: (provided) => {
  //   return {
  //     ...provided,
  //     color: "var(--select-field-color, #2A2E3B)",
  //   };
  // },
  multiValue: (provided) => {
    return {
      ...provided,
      background: "var(--select-field-multi-background-color, #004071)",
      color: "var(--select-field-multi-color, #ffffff)",
      borderRadius: 4,
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      color: "var(--select-field-multi-cross-color, #fffff)",
      "&:hover": {
        backgroundColor:
          "var(--select-field-multi-cross-hover-background-color, #004071)",
        color: "var(--select-field-multi-cross-hover-color, #ffffff)",
        cursor: "pointer",
      },
    };
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: "var(--select-field-multi-color, #ffffff)",
      fontSize: "var(--select-field-multi-font-size, 0.8125rem)",
      fontWeight: "var(--select-field-multi-font-weight, 400)",
      padding: "var(--select-field-multi-padding, 0.5rem 1rem)",
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
    };
  },
};

export default styles;
