import cx from "classnames";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import qs from "query-string";
import React, { useState, useRef } from "react";

import Icon from "../Icon/Icon";

SearchForm.propTypes = {
  settings: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  placeholderText: PropTypes.string,
};

export default function SearchForm({
  placeholderText,
  settings,
  id,
  ...restProps
}) {
  const { searchButtonText, searchLabelText, searchPlaceholderText } = settings;
  const [searchInput, setSearchInput] = useState("");

  let submitRef = useRef(null);
  let inputRef = useRef(null);

  const inputProps = {
    id: id || "search-form-query",
    name: "query",
    className: "c-search-form__field",
    type: "search",
    placeholder: placeholderText || searchPlaceholderText,
    value: searchInput,
    ref: inputRef,
    "aria-label": "Sökformulärinmatning",
  };

  return (
    <form
      className="c-search-form"
      role="search"
      onSubmit={(event) => {
        event.preventDefault();
        navigate(`/sok?${qs.stringify({ query: searchInput })}`);
      }}
      ref={submitRef}
      {...restProps}
    >
      <label htmlFor="search-form-query" className="u-visually-hidden">
        {searchLabelText}
      </label>
      <div
        className={cx(
          "c-search-form__wrapper",
          searchInput && "c-search-form--not-empty",
        )}
      >
        <input
          {...inputProps}
          onChange={(event) => {
            setSearchInput(event.target.value);
          }}
          placeholder="Sök&hellip;"
        />
        <button
          className="c-search-form__submit"
          type="submit"
          aria-label={searchButtonText}
        >
          <Icon name="search-wide" size="12px" />
        </button>
      </div>
    </form>
  );
}
