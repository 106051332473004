import WrappedModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import useModularityArea from "@municipio/gatsby-theme-basic/src/hooks/useModularityArea";
import useModularityModule from "@municipio/gatsby-theme-basic/src/hooks/useModularityModule";
import { parseColumnWidth } from "@municipio/gatsby-theme-basic/src/utils";
import { PageGridItem } from "@whitespace/components";
import clsx from "clsx";
import React from "react";

export default function ModuleWrapper({ className, ...restProps }) {
  const {
    // displayGrid,
    defaultModuleColSpan,
  } = useModularityArea();
  const { columnWidth, module } = useModularityModule();
  const moduleType = module?.contentType?.node?.name;
  // const MaybePageGridItem = displayGrid ? PageGridItem : React.Fragment;
  // const pageGridItemProps = displayGrid ? { colSpan } : {};
  return (
    <PageGridItem
      colSpan={
        parseColumnWidth(columnWidth) || defaultModuleColSpan || undefined
      }
    >
      <WrappedModuleWrapper
        className={clsx(moduleType === "mod-text" && "c-article", className)}
        {...restProps}
      />
    </PageGridItem>
  );
}

ModuleWrapper.propTypes = WrappedModuleWrapper.propTypes;
