import { TimeInterval } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import cx from "classnames";
import React from "react";

export default function EventSectionDates({
  occasions,
  className,
  ...restProps
}) {
  return (
    <div className={cx("event__details", className)} {...restProps}>
      <ul className="event__list">
        {occasions.map((occasion, index) => {
          return (
            <li key={index} className="event__list-item">
              <TimeInterval
                startDate={occasion.start_date}
                endDate={occasion.end_date}
                format={{
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  // weekday: "short",
                  hour: "numeric",
                  minute: "numeric",
                }}
              />
              {occasion.location ? `, ${occasion.location.title}` : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
