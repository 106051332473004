import FormFieldDescription from "@whitespace/components/dist/components/FormFieldDescription";
import FormFieldError from "@whitespace/components/dist/components/FormFieldError";
import FormFieldWrapper from "@whitespace/components/dist/components/FormFieldWrapper";
import * as defaultStyles from "@whitespace/components/dist/components/FormRadioGroupField.module.css";
import clsx from "clsx";
import { Field } from "formik";
import PropTypes from "prop-types";
import React from "react";

import FormFieldLabel from "../FormFieldLabel";

FormRadioGroupField.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function FormRadioGroupField({
  className,
  options,
  styles = defaultStyles,
  ...restProps
}) {
  return (
    <FormFieldWrapper
      className={clsx(styles.component, className)}
      {...restProps}
    >
      {({ name, controlProps }) => (
        <fieldset className={styles.control} {...controlProps}>
          <FormFieldLabel as="legend" className={styles.label} />
          <FormFieldDescription className={styles.description} />
          <FormFieldError className={styles.error} />
          <div className={styles.list}>
            {options.map((option, index) => {
              return (
                <label className={styles.itemLabel} key={index}>
                  <Field
                    className={styles.item}
                    type="radio"
                    name={name}
                    value={option.value}
                  />
                  {option.label}
                </label>
              );
            })}
          </div>
        </fieldset>
      )}
    </FormFieldWrapper>
  );
}
