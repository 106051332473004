import * as breadcrumbStyles from "./Breadcrumbs.module.css";

import "./index.props.css";

import "./Button.props.css";
import "./Card.props.css";
import "./FormModule.props.css";
import "./Grid.props.css";
import "./GridMenu.props.css";
import "./Link.props.css";
import "./SkipTo.props.css";
import "./List.props.css";
import "./ModuleWrapper.props.css";
import "./Teaser.props.css";

const theme = {
  footer: {
    background: "#184B69",
    color: "#fff",
    linkColor: "#fff",
  },
  breadcrumbs: {
    styles: breadcrumbStyles,
  },
  image: {
    caption: {
      color: "var(--color-neutral-700)",
    },
  },
  fileslistModule: {
    item: {
      background: "#fff",
      borderRadius: "0.25rem",
    },
  },
  link: {
    color: "#005eb8",
  },
  boxNavigationLinkBackground: "#C9E2EA",
  boxNavigationLinkHoverBackground: "#4B7988",
  boxNavigationLinkHoverColor: "#fff",
  boxBackground: "#fff",
  pageThemeColorMuted: "var(--box-navigation-link-background)",
  pageThemeColorDark: "var(--box-navigation-link-hover-background)",
  noticeModule: {
    headingColor: "#f00",
  },
  color: {
    danger: "#d92f2f",
    dangerText: "#fff",
    infoText: "#fff",
  },
  headerFrontpageMenuBackgroundImage:
    "radial-gradient(45.81% 45.81% at 65.42% 51.61%, #2FB6D1 0%, rgba(50, 183, 209, 0) 100%), linear-gradient(180deg, #164A69 2.26%, rgba(22, 74, 105, 0) 66.61%), linear-gradient(159.65deg, #164A69 31.93%, #59ACBD 66.68%), radial-gradient(156.62% 518.38% at 97.99% 123.87%, #04AAD3 0%, #C5DEC6 100%);",
  tableModuleMarginBottom: "2rem",
  tableTitleFontSize: "1rem",
  tableTitleFontWeight: "700",
  tableHeadFontSize: "0.875rem",
  tableHeadFontWeight: "700",
  tableHeadBorderBottom: "2px solid var(--color-neutral-225)",
  tableHeadBorderBottomColor: "var(--color-neutral-300)",
  tableHeadBackground: "var(--color-light)",
  tableCellBackground: "var(--color-light)",
  tableCellFontSize: "0.875rem",
  tableCellPadding: "0.75rem 1rem",
  tableCellBorder: "1px solid var(--color-neutral-225)",
  moduleContact: {
    background: "#f6f6f6",
    surface: "#4d4d4d",
    actionColor: "#184B69",
    actionDecoration: "underline",
    fontWeight: "400",
    fontStyle: "normal",
    borderWidth: "0",
    borderStyle: "inherit",
    borderColor: "transparent",
    borderRadius: "var(--box-border-radius, 0.25rem)",
    spacing: "1rem",
    iconColor: "#184B69",
    divider: "1px solid #eee",
    groupSpacing: "1rem",
    dividerSpacing: "1rem",
    gridDefault: "15rem",
    gridNarrow: "15rem",
    labelWeight: "700",
    labelSpacing: "0.25rem",
    labelSize: "var(--base-font-size)",
  },
  searchHitsTopSpacing: "0",
  searchHitsBottomSpacing: "2rem",
  header: {
    background: "#164A69",
    bg: "#164A69",
    color: "var(--color-text-inverse)",
  },
  postsModuleHeader: {
    icon: { name: "caret-right" },
  },
  searchHit: {
    backgroundColor: "transparent",
    meta: {
      color: "var(--color-neutral-700)",
    },
  },
  searchForm: {
    spacing: "0.85rem",
    submit: {
      color: "#616161",
      backgroundColor: "#eee",
      hover: {
        backgroundColor: "#dfdfdf",
      },
    },
    border: "1px solid #dfe1e4",
  },
  form: {
    input: {
      "box-sizing": "border-box",
    },
    label: {
      display: "block",
      margin: {
        bottom: "0.25rem",
      },
    },
  },
  headerFlyOut: {
    toggle: {
      borderColor: "var(--header-primary-button-bg)",
      color: "var(--header-primary-button)",
      backgroundColor: "var(--header-primary-button-bg)",
      hover: {
        borderColor: "var(--header-primary-button-bg)",
        color: "var(--header-primary-button)",
        backgroundColor: "var(--header-primary-button-bg)",
      },
    },
  },
  postsModuleIndexItem: {
    dateFormat: { weekday: "long", day: "numeric", month: "long" },
  },
  treeMenu: {
    row: {
      padding: "0",
    },
    link: {
      color: "var(--color-text)",
      hover: {
        color: "var(--color-text)",
      },
    },
  },
  headerMainMenu: {
    background: "var(--color-primary)",
    color: "var(--color-text-inverse)",
  },

  siteBanner: {
    color: "#fff",
    backgroundColor: "#164a69",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.875rem",
  },

  // Cookie Consent
  ccBackground: "#184b69",
  ccForeground: "var(--color-neutral-0)",
  ccDeclineBackground: "#51595d",
  ccDeclineForeground: "#fff",
  ccApproveBackground: "#fff",
  ccApproveForegound: "#184b69",
  ccFocus: "red",
  ccTitleSize: "1.5rem",
  ccBodySize: "16px",
  ccButtonBorderRadius: "0.25rem",
  ccButtonPadding: "0.5rem 1rem",
  ccButtonGroupMargin: "1rem 0 0 0",
};

export default theme;
