// extracted by mini-css-extract-plugin
export var columns = "ContactCard-module--columns--2m9Ns";
export var columnsNarrow = "ContactCard-module--columnsNarrow--1wyJr";
export var component = "ContactCard-module--component--9UH3C";
export var divider = "ContactCard-module--divider--tcQre";
export var iconLabel = "ContactCard-module--iconLabel--+Bslk";
export var image = "ContactCard-module--image--cb+cs";
export var label = "ContactCard-module--label--IdUc5";
export var name = "ContactCard-module--name--bBfXC";
export var photo = "ContactCard-module--photo--hyRCj";
export var social = "ContactCard-module--social--LPJFl";
export var wrapper = "ContactCard-module--wrapper--YGIoZ";