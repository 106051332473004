import { H } from "@jfrk/react-heading-levels";
import { TimeInterval } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import cx from "classnames";
import React from "react";

import Icon from "../../Icon/Icon";

export default function EventSectionDetails({
  location,
  occasion,
  status,
  categories,
  className,
  ...restProps
}) {
  return (
    <div className={cx("event__details-summary", className)} {...restProps}>
      {
        <div className="event__details-summary-content">
          <H className="event__title">
            <Icon name="ticket-clock" size="20" className="event__title-icon" />
            Tidpunkt
          </H>
          {status === "cancelled" && (
            <span className="event__status event__status--canceled">
              Inställt
            </span>
          )}
          <TimeInterval
            startDate={occasion.start_date}
            endDate={occasion.end_date}
            format={{
              year: "numeric",
              month: "long",
              day: "numeric",
              // weekday: "short",
              hour: "numeric",
              minute: "numeric",
            }}
          />
        </div>
      }
      {location && (
        <div className="event__details-summary-content">
          <H className="event__title">
            <Icon name="earth-pin-2" size="20" className="event__title-icon" />
            Plats
          </H>
          <p>{location.title}</p>
        </div>
      )}
      {categories && (
        <div className="event__details-summary-categories">
          <H className="event__title">
            <Icon name="info" size="20" className="event__title-icon" />
            Kategorier
          </H>
          {categories.map((category, index) => {
            return (
              <span key={index} className="event__categories-item">
                {category}
              </span>
            );
          })}
        </div>
      )}
      {/* {from && (
        <div className="event__details-summary-content">
          <H className="event__title">
            <Icon name="info" size="20" className="event__title-icon" />
            Ålder
          </H>
          <p>Från {from} år</p>
        </div>
      )} */}
    </div>
  );
}
