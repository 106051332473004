import { useStaticQuery, graphql } from "gatsby";

export default function usePageHeader() {
  return useStaticQuery(graphql`
    query PageHeader {
      wp {
        acfOptionsContent {
          pageHeader {
            boxNavigationPlacing
          }
        }
      }
    }
  `).wp.acfOptionsContent.pageHeader;
}
