import { GridMenu } from "@whitespace/components";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import clsx from "clsx";
import { kebabCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

export default function HeaderFrontPageMenu({ className, ...restProps }) {
  const { contentNode: { isFrontPage } = {} } = usePageContext();
  const { t } = useTranslation();
  let menu = useMenu("FRONTPAGE");

  if (!isFrontPage) {
    return null;
  }

  if (!menu?.items?.length) {
    return null;
  }

  /* Todo: Added to display icons & color correctly */
  let items = menu.items.map(
    ({
      description,
      icon,
      label,
      pageThemeColor,
      target,
      url,
      // connectedObject
    }) => ({
      description,
      icon: icon
        ? {
            name: icon,
            backgroundColor: pageThemeColor?.theme
              ? `var(--brand-color-${kebabCase(pageThemeColor.theme)})`
              : null,
          }
        : null,
      label,
      target,
      url,
    }),
  );

  return (
    <GridMenu
      {...restProps}
      items={items}
      className={clsx("u-hidden-print o-container", className)}
      aria-label={t("Primary navigation")}
    />
  );
}
